var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-switch", {
        key: _vm.id,
        attrs: {
          "hide-details": "",
          inset: "",
          id: _vm.id,
          disabled: _vm.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _vm.count
                  ? _c(
                      "span",
                      {
                        staticClass: "switch_label",
                        attrs: { id: _vm.id + "_label" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.label) +
                            "(" +
                            _vm._s(_vm.count) +
                            ") "
                        ),
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "switch_label",
                        attrs: { id: _vm.id + "_label" },
                      },
                      [_vm._v(" " + _vm._s(_vm.label))]
                    ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }