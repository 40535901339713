<template>
  <div>
    <v-switch hide-details inset :id="id" :key="id" v-model="model" :disabled="disabled">
      <template v-slot:label>
        <span :id="id + '_label'" class="switch_label" v-if="count"> {{ label }}({{ count }}) </span>
        <span :id="id + '_label'" class="switch_label" v-else> {{ label }}</span>
      </template>
    </v-switch>
  </div>
</template>

<script>
export default {
  name: 'AGSwitch',
  props: {
    id: {
      type: String,
      required: true,
    },
    count: {
      type: [Number, String],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('change', value);
        this.$emit('input', value);
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
